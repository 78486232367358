import { Icon } from '@chakra-ui/react'
import {
  WifiIcon,
  CalendarIcon,
  PaperAirplaneIcon,
  InboxIcon,
  StarIcon,
  ChartPieIcon,
  GiftIcon,
  HeartIcon,
  UserCircleIcon,
  BellAlertIcon,
  CreditCardIcon,
  UserPlusIcon,
  Cog6ToothIcon,
  TagIcon,
  PaintBrushIcon,
  ArchiveBoxIcon,
  BellIcon,
  FaceFrownIcon,
  FaceSmileIcon,
  ChatBubbleLeftRightIcon,
  RocketLaunchIcon,
  ArrowsRightLeftIcon,
  ViewColumnsIcon,
  BuildingStorefrontIcon,
} from '@heroicons/react/16/solid'
import type { ReactElement } from 'react'
import type { AddonTypes } from '@/connect-types/billing/organisation.type'
import {
  AnalyticsIcon,
  FacebookLogo,
  GoogleIcon,
} from '@/common/Icons/CustomIcons'
import TripadvisorIcon from '@/ux/icons/tripadvisor'
import { VendorType } from '@/state/morpheus/types/venue'

export interface MenuType {
  title: string
  key: string
  locationType?: number[]
  icon?: ReactElement
  role?: number[]
  locationCount?: number
  addOnFlag?: AddonTypes
  news?: boolean
  vendor?: VendorType[]
  requiresLocation?: boolean
  notVendor?: string[]
  experimental?: boolean
  sub?: MenuType[]
  subCount?: number
  hiddenSub?: boolean
  animatedIcon?: any
  dropdown?: boolean
  fillStatKey?: string
  orgType?: 'default' | 'partner'
  hideProd?: boolean
}

export const parentIconSize = 24

export const profileOrgNavigationItems = [
  {
    title: 'Profile',
    key: 'account/profile',
    icon: UserCircleIcon,
  },
  {
    title: 'Notifications',
    key: 'account/notifications',
    icon: BellAlertIcon,
  },
]

export const orgNavigationItems = [
  {
    title: 'Billing',
    key: 'organisation/billing',
    icon: CreditCardIcon,
  },

  {
    title: 'Apps Marketplace',
    key: 'marketplace/apps',
    icon: BuildingStorefrontIcon,
  },

  {
    title: 'Team',
    key: 'organisation/team',
    icon: UserPlusIcon,
  },
  {
    title: 'Settings',
    key: 'organisation/settings',
    icon: Cog6ToothIcon,
  },
  {
    title: 'Customer tags',
    key: 'organisation/tags',
    icon: TagIcon,
  },
  {
    title: 'Brand kits',
    key: 'organisation/brand',
    icon: PaintBrushIcon,
  },
  {
    title: 'Event log',
    key: 'organisation/event-log',
    icon: ArchiveBoxIcon,
  },

  {
    title: 'Switch organisation',
    key: '/',
    icon: ArrowsRightLeftIcon,
  },
]
