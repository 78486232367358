import { ScrollArea } from '@/common/scroll-area'
import { usePageTitle } from '@/hooks/usePageTitle'
import useWindowWidth from '@/hooks/useWindowWidth'
import { useUserConfig } from '@/state/morpheus/user-config/helpers'
import { ChevronLeftIcon, ViewColumnsIcon } from '@heroicons/react/16/solid'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { AnimatePresence, motion, type Variants } from 'framer-motion'
import {
  Fragment,
  type ReactElement,
  type ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  matchRoutes,
  NavLink,
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router-dom'
import {
  Badge,
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  Icn,
  iconButtonVariants,
  Portal,
  SIconButton,
} from 'tailwind-ui'
import Description from 'tailwind-ui/src/typeography/description'
import Heading from 'tailwind-ui/src/typeography/heading'
import { Breadcrumbs } from './breadcrumbs'
import {
  PanelLeftCloseIcon,
  PanelRightCloseIcon,
  PanelRightIcon,
} from 'lucide-react'
import { useDebounceValue, useLocalStorage, useWindowSize } from 'usehooks-ts'
import { debounce } from 'lodash'
import { useGoBack } from '@/utils/router/useGoBack'

const tabs = [
  { name: 'My Account', href: '#', current: false },
  { name: 'Company', href: '#', current: false },
  { name: 'Team Members', href: '#', current: true },
  { name: 'Billing', href: '#', current: false },
]

export function TitleBar({
  backTo = false,
  title,
  subtitle = '',
  alwaysShow = false,
  children,
}: {
  title: string
  backTo?: string | boolean
  alwaysShow?: boolean
  children?: ReactElement
  subtitle: string
}) {
  const navigate = useNavigate()
  return (
    <>
      <div className="m-2 sticky top-2 bg-neutral-100/90 dark:bg-neutral-900/90 border backdrop-blur-md border-black/5 dark:border-white/5  z-30 left-0  inline-flex w-fit items-center p-1 rounded-full gap-1">
        <SIconButton
          size="sm"
          variant="default"
          className="backdrop-blur-none"
          icon={ChevronLeftIcon}
          onClick={() => {
            navigate(-1)
          }}
        />
      </div>
    </>
  )
}

export interface LinkType {
  value: string
  key?: string
  hidden?: boolean
  icon?:
    | React.ForwardRefExoticComponent<
        Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
          title?: string | undefined
          titleId?: string | undefined
        } & React.RefAttributes<SVGSVGElement>
      >
    | string
  children?: LinkType[]
}

const RenderLinks = ({
  links,
  isNestedNav,
}: {
  links: LinkType[]
  isNestedNav: boolean
}) => {
  return links.map((link, index) => (
    <NavLink
      end={!!link.children}
      to={link.key ?? index}
      className={cn(
        'capitalize px-4 py-2  rounded-xl fill-gray-700 dark:fill-gray-300 text-nowrap aria-[current=page]:bg-black/10 dark:aria-[current=page]:bg-white/10 hover:bg-black/5 dark:hover:bg-white/5',
        {
          //  ['py-2']: isTouchDevice(),
          // 'h-8': !isNestedNav,
        }
      )}
      size={isNestedNav ? 'lg' : 'base'}
      variant={isNestedNav ? 'ghost_default' : 'ghost_default'}
      key={link.key ?? index}
    >
      <div className="flex items-center gap-2">
        {typeof link.icon !== 'string' && link.icon ? (
          <div className="w-6 h-6 flex  items-center">
            <Icn icon={link.icon} />
          </div>
        ) : undefined}
        {typeof link.icon === 'string' ? (
          <div className="w-6 h-6 flex dark:bg-white/80 bg-black/80 rounded-xl p-0.5 items-center">
            <img
              className="object-cover h-full w-full rounded-md"
              src={link.icon}
              alt={link.value}
            />
          </div>
        ) : undefined}
        <span className="truncate overflow-hidden">{link.value}</span>
      </div>
    </NavLink>
  ))
}

const container = {
  hidden: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
  show: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
}

const listItem: Variants = {
  hidden: {
    x: -50,
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000, velocity: -100 },
    },
  },
}

function RecursiveMenuSection({ link }: { link: LinkType }) {
  if (link.children) {
    return (
      <>
        <DropdownMenuLabel>{link.value}</DropdownMenuLabel>

        {link.children.map((childLink, index) => (
          <RecursiveMenuSection key={index} link={childLink} />
        ))}
      </>
    )
  }

  return (
    <DropdownMenuItem asChild>
      <NavLink to={link.key ?? ''} className="size-full">
        <div className="flex items-center">
          {link.icon && typeof link.icon !== 'string' ? (
            <link.icon className="size-4 dark:fill-white/30 fill-black/30 mr-2" />
          ) : null}
          {typeof link.icon === 'string' && (
            <div className="w-4 h-4 mr-2">
              <img
                className="object-cover h-full w-full rounded-md"
                src={link.icon}
              />
            </div>
          )}
          <span>{link.value}</span>
        </div>
      </NavLink>
    </DropdownMenuItem>
  )
}

// Add these animation variants near the other variants
const pageVariants: Variants = {
  hidden: { opacity: 0, x: 0, y: 20 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, transition: { duration: 0.5 } },
}

const MotionScrollArea = motion.create(ScrollArea)

const scrollMotionVariants = (width: number) => ({
  enter: {
    width: width < 768 ? '100%' : 320,
    className: 'h-full',
  },
  exit: {
    width: 48,
    height: 'auto',
    transition: {
      duration: 0.2,
    },
  },
})

function TabbedNavLayout({
  links = [],
  root = '',
  title = '',
  subtitle = '',
  isRootOutlet = false,
  context,
  rightElement,
  rightClass,
  backTo = undefined,
  outletRequiresContext = false,
  outletClassName = undefined,
  isPadded = false,
  children,
}: {
  links: LinkType[]
  root: string
  title: string
  subtitle: string
  backTo?: string | number
  isRootOutlet?: boolean
  context?: unknown
  rightElement?: ReactElement
  rightClass?: string
  outletRequiresContext?: boolean
  outletClassName?: string | undefined
  isPadded?: boolean
  children?: ReactNode
  idMap?: Record<string, string>
}) {
  const location = useLocation()
  const { pathname } = useLocation()
  const { width } = useWindowWidth()
  const variants = useMemo(() => scrollMotionVariants(width), [width])
  const isNestedNav = useMemo(() => {
    if (width < 1024) {
      return true
    }
    const child = links.filter((item) => (item?.children ?? []).length > 0)
    if (child.length === 0) {
      return true
    }
    return true
  }, [links, width])

  const push = useNavigate()
  const item = matchRoutes(
    links.map(({ key }) => ({
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- allow
      path: `${root}/${key}`,
    })),
    location
  )

  usePageTitle(title)

  const path = useMemo(() => {
    if (!item) return ''
    const rootName = root.split('/').pop()
    const name = item[0].pathname.split('/').pop()

    if (name === rootName) {
      return links.find(({ key }) => key === '')?.value
    }
    return links.find(({ key }) => key === name)?.value ?? ''
  }, [item, links, root])

  const isInRootPath = useMatch({
    path: root,
    end: false,
  })
  console.log({ path, root, isInRootPath })
  useEffect(() => {
    if (isRootOutlet) return
    if (outletRequiresContext && !context) return
    document.body.classList.add('page-has-side-nav')

    return () => {
      document.body.classList.remove('page-has-side-nav')
    }
  }, [isRootOutlet, outletRequiresContext, context])

  const config = useUserConfig()

  const [isSidebarOpen, setIsSidebarOpen] = useLocalStorage(
    'isSidebarOpen',
    width >= 768
  )
  const [debouncedSidebarState] = useDebounceValue(isSidebarOpen, 300)

  useEffect(() => {
    debounce(() => {
      setIsSidebarOpen(width >= 768)
    }, 300)
  }, [width])

  useEffect(() => {
    if (width < 768) {
      setIsSidebarOpen(width >= 768)
    }
  }, [pathname, width])

  const matchingRoot = useMatch(root)
  console.log({ isInRootPath, location, matchingRoot, root })

  const goBack = useGoBack({
    root,
    rootMatch: matchingRoot?.pattern,
    backTo: !backTo ? undefined : backTo.toString(),
    // backTo: '/some/explicit/path', // (optional override)
  })

  return (
    <motion.div
      variants={{
        enter: {
          paddingLeft: width >= 768 && !isRootOutlet ? 320 : 0,
          className: ' ',
          transition: {
            type: 'spring',
            stiffness: 300,
            damping: 30,
          },
        },
        exit: {
          paddingLeft: 0, // Matches the sidebar width
          transition: {
            type: 'spring',
            stiffness: 300,
            damping: 30,
          },
        },
      }}
      initial={false}
      exit="exit"
      animate={isSidebarOpen ? 'enter' : 'exit'}
      className="overflow-hidden  relative flex-1 flex flex-col md:flex-row min-h-full"
    >
      <MotionScrollArea
        className={cn(
          'bg-neutral-100/90 dark:bg-neutral-900/90 backdrop-blur-lg',
          'overflow-x-hidden  absolute',
          'left-0 w-full md:w-[320px] h-full inset-0  transition-colors top-0 duration-300  max-sm:h-dvh transform z-50 md:z-20',
          {
            hidden: isRootOutlet,
            'bg-neutral-100/70 dark:bg-neutral-900/70': !isSidebarOpen,

            'left-0':
              config.action_bar.position === 'left' &&
              config.action_bar.is_fixed,
            '!overflow-y-hidden': !isSidebarOpen,
          }
        )}
        animate={isSidebarOpen ? 'enter' : 'exit'}
        initial={false}
        variants={{
          enter: {
            x: 0,

            transition: {
              type: 'spring',
              stiffness: 300,
              damping: 40,
            },
          },
          exit: {
            x: width >= 1024 ? -316 : '-100%', // Matches the sidebar width
            transition: {
              type: 'spring',
              stiffness: 300,
              damping: 30,
            },
          },
        }}
      >
        <div
          className={cn('flex justify-between  p-2 pl-4 items-start relative', {
            //'items-center md:flex-col self-center': !debouncedSidebarState,
          })}
        >
          {isSidebarOpen ? (
            <div className={cn('flex flex-col pr-12')}>
              <Heading className="truncate">{title}</Heading>

              {subtitle ? (
                <Description className="truncate">{subtitle}</Description>
              ) : null}
              <Breadcrumbs isSlim />
            </div>
          ) : null}

          {!isRootOutlet && (
            <Portal
              className={cn(
                'flex  absolute transition-all duration-300 top-2 w-12  z-50 max-md:right-6 md:left-0  justify-center items-center',
                {
                  'md:left-16':
                    config.action_bar.position === 'left' &&
                    config.action_bar.is_fixed &&
                    config.action_bar.icon,
                  'md:left-44':
                    config.action_bar.position === 'left' &&
                    config.action_bar.is_fixed &&
                    !config.action_bar.icon,
                  'md:left-2':
                    config.action_bar.position === 'left' &&
                    !config.action_bar.is_fixed &&
                    !isSidebarOpen,
                  'md:left-56':
                    config.action_bar.position === 'left' &&
                    !config.action_bar.is_fixed &&
                    isSidebarOpen,

                  'md:left-80 pr-6':
                    isSidebarOpen &&
                    config.action_bar.position === 'left' &&
                    config.action_bar.is_fixed &&
                    config.action_bar.icon,
                  'md:left-[408px]':
                    isSidebarOpen &&
                    config.action_bar.position === 'left' &&
                    config.action_bar.is_fixed &&
                    !config.action_bar.icon,
                  'flex-row': isSidebarOpen,
                  'md:flex-col': !isSidebarOpen,
                }
              )}
            >
              <SIconButton
                isRound
                variant="ghost_default"
                icon={
                  !debouncedSidebarState
                    ? PanelRightCloseIcon
                    : PanelLeftCloseIcon
                }
                onClick={() => {
                  setIsSidebarOpen((s) => !s)
                }}
              />

              <SIconButton
                isRound
                variant="ghost_default"
                icon={ChevronLeftIcon}
                onClick={() => {
                  goBack()
                }}
              />
            </Portal>
          )}
        </div>

        <nav
          className={cn(
            'flex flex-1 py-2 flex-col justify-between space-y-6 px-2'
          )}
          aria-label="Tabs"
        >
          <div
            className={cn(' flex-1 pb-6 flex flex-col w-full', {
              'lg:flex-col gap-1': isNestedNav,
              'gap-1': !isNestedNav,
            })}
          >
            {links
              .filter((it) => it.hidden !== true)
              .map((link, index) => (
                <div
                  key={!link?.key ? index : link.key}
                  className={cn('flex truncate lg:overflow-hidden', {
                    'flex-col w-full gap-1': isNestedNav,
                    'gap-3 items-center': !isNestedNav,
                  })}
                >
                  {(link.children?.length ?? 0) > 0 ? (
                    <>
                      {link.key ? (
                        <NavLink
                          end={link.key === ''}
                          to={link.key}
                          className={({ isActive }) =>
                            cn(
                              'font-semibold items-center leading-6 text-neutral-800 dark:text-neutral-200 gap-2 px-4 mt-4 mb-2 flex',
                              {
                                'is-active group': isActive,
                              }
                            )
                          }
                        >
                          <span>{link.value}</span>
                          <div className="w-2 h-2 opacity-0 bg-green-500 border rounded-full group-[.is-active]:opacity-100 hidden" />
                        </NavLink>
                      ) : (
                        <p className="font-semibold items-center leading-6 text-neutral-800 dark:text-neutral-200  gap-2 px-4 mt-2 mb-2 flex">
                          {link.value}
                        </p>
                      )}

                      {RenderLinks({
                        links: link.children ?? [],
                        isNestedNav,
                      })}
                      {index <
                        links.filter((it) => it.hidden !== true).length - 1 && (
                        <div className="my-0.5 mx-4  h-px bg-black/5 dark:bg-white/5" />
                      )}
                    </>
                  ) : (
                    RenderLinks({ links: [link], isNestedNav })
                  )}
                </div>
              ))}
            {rightElement ? (
              <div className="lg:hidden flex items-center gap-2">
                {rightElement}
              </div>
            ) : null}
          </div>
          {rightElement ? (
            <div
              className={cn(
                'self-center !max-lg:mt-4 max-lg:hidden',
                rightClass
              )}
            >
              {rightElement}
            </div>
          ) : null}
          <p className="font-semibold capitalize hidden">{path}</p>
        </nav>
      </MotionScrollArea>

      <div
        key={location.key}
        className={cn(
          'flex-1 w-full  relative pb-safe md:pb-0 content-area flex flex-col overflow-x-hidden lg:overflow-y-auto scroll-smooth',
          {
            'px-0': !isPadded,
          },
          {
            'lg:ml-14': !isSidebarOpen && !isRootOutlet,
          },
          outletClassName
        )}
      >
        {children}
        <Outlet context={context} />
      </div>
    </motion.div>
  )
}

export default TabbedNavLayout
