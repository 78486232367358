'use client'
//import Image from 'next/image'
import { Transition } from '@headlessui/react'
import { useState, lazy, Suspense, useEffect } from 'react'
import { generateImage } from 'ui/helpers/image'
import { cn } from '../helpers/classnames'

const Image = lazy(() => import('next/image'))

const ReactPlayer = lazy(() => import('react-player'))

/**
 *
 * @todo remove this bgStyle prop
 * in general we should try and not prop drill
 * the theme config should be controlled using brand kits and tailwind styles
 *
 */

export default function Background({
  image = null,
  bgStyle = 'gradient',
}: {
  image: string | null
  bgStyle: 'gradient' | 'solid'
}) {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    document.body.classList.add('bg-background-500')
    return () => {
      document.body.classList.remove('bg-background-500')
    }
  }, [])

  if (!image) return null
  return (
    <>
      <Transition
        appear
        as="div"
        className="absolute h-[50%] md:h-full md:fixed inset-0 in-frame:hidden"
        enter="transition-opacity duration-[1000ms]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        show={loaded}
        unmount={false}
      >
        <Suspense fallback={<></>}>
          {image.endsWith('.mp4') ? (
            <ReactPlayer
              playing
              loop
              muted
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
              }}
              config={{
                file: {
                  attributes: {
                    className: 'object-cover max-lg:object-top',
                  },
                },
              }}
              width="100%"
              height="100%"
              controls
              playsinline
              url={image}
              className="object-cover max-h-screen "
              onReady={() => {
                setLoaded(true)
              }}
            />
          ) : (
            <Image
              alt="background image"
              className="object-cover min-h-[100%] sm:min-h-[50%] h-full w-full max-md:object-top"
              fill
              id="background-image"
              onLoad={() => {
                setLoaded(true)
              }}
              priority
              quality={85}
              sizes="(max-width: 768px) 100vw"
              src={
                /\.(svg|gif)$/i.exec(image)
                  ? generateImage(image, 'background')
                  : image
              }
              unoptimized={Boolean(/\.(svg|gif)$/i.exec(image))}
            />
          )}
        </Suspense>
      </Transition>
      <div
        className={cn(
          'in-frame:hidden opacity-1 bg-gradient-to-b from-transparent from-0% to-background to-50% md:to-55% absolute md:fixed w-full h-[90%]  md:h-full',
          bgStyle === 'solid'
            ? 'h-[60%] md:h-[40%] md:bottom-0 bg-background to-transparent'
            : 'top-2 md:top-0'
        )}
      />
    </>
  )
}
