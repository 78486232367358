import type { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { toast } from 'sonner'
import type { AxiosError, AxiosRequestConfig } from 'axios'
import { retry, createApi } from '@reduxjs/toolkit/query/react'
import isEqual from 'lodash/isEqual'
import { endOfDay, startOfDay } from 'date-fns'
import {
  type ServiceCursorPaginatedResponseType,
  ServiceErrorHandler,
} from '@/connect-types/backend/service'
import { customAxios } from '@/utils/axiosHelper'
import { buildUrl } from '@/utils/common'
import {
  type ReviewSentimentType,
  type UserReviewPlatforms,
} from '@/connect-types/reviews/reviews.type'
import config from '@/config'
import { shouldBailout } from '../helpers'
import {
  type ReviewResponseType,
  type ReviewPageType,
  type SimpleReviewReportType,
  type ReviewReportType,
} from '../morpheus/types/reviews'

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<{
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
  }> =>
  async ({ url, method, data, params }) => {
    try {
      let axiosUrl = baseUrl + url
      if (url.startsWith('https://')) {
        axiosUrl = url
      }

      const result = await customAxios({
        url: axiosUrl,
        method,
        data,
        params,
      })

      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError

      if (
        method !== 'get' &&
        err.response?.status !== 401 &&
        err.response?.status !== 404 &&
        err.response?.status !== 429 &&
        !url.includes('segment')
      ) {
        ServiceErrorHandler(err)
      }

      if (shouldBailout(err)) {
        retry.fail(err)
      }

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

const tankApi = createApi({
  reducerPath: 'tank',
  baseQuery: retry(axiosBaseQuery({ baseUrl: config.url.tank }), {
    maxRetries: 2,
  }),
  tagTypes: ['ReviewPage', 'ReviewResponses', 'GoogleConnection'],
  endpoints: (build) => ({
    getOauthToken: build.query<
      { success: boolean; error?: string; details?: unknown },
      { code: string; oauth_uid: string; state: string }
    >({
      query: ({ code, oauth_uid, state }) => ({
        method: 'GET',
        url: `/oauth/token`,
        params: { code, oauth_uid, state },
      }),
    }),
    getGoogleConnection: build.query<
      { isConnected: boolean },
      { orgId: string }
    >({
      query: ({ orgId }) => ({
        url: `/${orgId}/google/connection`,
        method: 'GET',
      }),
      providesTags: (_result, _error, params) => [
        { type: 'GoogleConnection', id: params.orgId },
      ],
    }),
    getOrgReviewPages: build.query<ReviewPageType[], { orgId: string }>({
      query: ({ orgId }) => ({
        method: 'GET',
        url: `/${orgId}/reviews/pages`, // branding_settings, template_id, deleted_at missing
      }),
      providesTags: (items = []) =>
        items.map((item) => ({ id: item.id, type: 'ReviewPage' })),
    }),

    deleteReviewPage: build.mutation<
      ReviewPageType,
      { orgId: string; id: string }
    >({
      query: ({ orgId, id }) => ({
        method: 'DELETE',
        url: `/${orgId}/reviews/pages/${id}`,
      }),
      transformResponse(item: ReviewPageType) {
        toast.success(`Review page deleted`)
        return item
      },
      invalidatesTags: (item) => [{ type: 'ReviewPage', id: item?.id }],
    }),

    getReviewsPage: build.query<ReviewPageType, { orgId: string; id: string }>({
      query: ({ orgId, id }) => ({
        method: 'GET',
        url: `/${orgId}/reviews/pages/${id}`,
      }),
      providesTags: (item) => [{ type: 'ReviewPage', id: item?.id }],
    }),

    updateReviewsPage: build.mutation<
      ReviewPageType,
      { orgId: string; page: ReviewPageType }
    >({
      query: ({ orgId, page }) => ({
        method: 'PUT',
        url: `/${orgId}/reviews/pages/${page.id}`,
        data: page,
      }),
      invalidatesTags: (item) => [{ type: 'ReviewPage', id: item?.id }],
      transformResponse(item: ReviewPageType) {
        toast.success('Review page updated')
        return item
      },
    }),

    createReviewsPage: build.mutation<
      ReviewPageType,
      { orgId: string; page: ReviewPageType }
    >({
      query: ({ orgId, page }) => ({
        method: 'POST',
        url: `/${orgId}/reviews/pages`,
        data: { ...page, org_id: orgId },
      }),
      invalidatesTags: (item) => [{ type: 'ReviewPage', id: item?.id }],
      transformResponse(item: ReviewPageType) {
        toast.success('Review page created')
        return item
      },
    }),

    updateReviewsPageBrand: build.mutation<
      unknown,
      { orgId: string; id: string; branding_id: string }
    >({
      query: ({ orgId, id, branding_id }) => ({
        method: 'PUT',
        url: `/${orgId}/reviews/pages/${id}/brand`,
        data: {
          branding_id,
        },
      }),
      invalidatesTags: (_1, _2, { id }) => [{ type: 'ReviewPage', id }],
      transformResponse(data) {
        toast.success('Brand kit updated')
        return data
      },
    }),

    getReviewResponses: build.query<
      ServiceCursorPaginatedResponseType<ReviewResponseType>,
      {
        orgId: string
        query: {
          review?: string
          review_page?: string
          serial?: string
          review_rating?: number
          review_platforms?: UserReviewPlatforms[]
          review_sentiment?: ReviewSentimentType
          done?: boolean
          sort_by?: 'asc' | 'desc'
          limit: number
          cursor: string
        }
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'GET',
        url: buildUrl(`/${orgId}/reviews`, query),
      }),
      providesTags: (items) => [
        ...(items?.data ?? []).map((item) => ({
          id: item.id,
          type: 'ReviewResponses' as const,
        })),
        { id: 'LIST', type: 'ReviewResponses' },
      ],
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        console.log({ queryArgs, endpointName })
        return JSON.stringify({
          orgId: queryArgs.orgId,
          params: {
            ...queryArgs.query,
            cursor: '',
          },
        })
      },
      merge: (currentCache, newItems) => {
        currentCache.links = newItems.links
        currentCache.meta = newItems.meta

        newItems.data.forEach((item) => {
          const current = currentCache.data.findIndex((it) => it.id === item.id)
          if (current >= 0) {
            currentCache.data[current] = item
          } else {
            currentCache.data.push(item)
          }
        })
      },
      forceRefetch({ currentArg, previousArg }) {
        console.log(
          { currentArg, previousArg },
          'queryArgs',
          isEqual(currentArg?.query, previousArg?.query)
        )
        return !isEqual(currentArg?.query, previousArg?.query)
        //  return currentArg !== previousArg
      },
    }),

    getReviewResponse: build.query<
      ReviewResponseType,
      {
        orgId: string
        id: string
      }
    >({
      query: ({ orgId, id }) => ({
        method: 'GET',
        url: `/${orgId}/reviews/${id}`,
      }),
      providesTags: (item) => [{ id: item?.id, type: 'ReviewResponses' }],
    }),

    updateReview: build.mutation<
      ReviewResponseType,
      {
        orgId: string
        id: string
        review: ReviewResponseType
      }
    >({
      query: ({ orgId, review, id }) => ({
        method: 'PUT',
        url: `/${orgId}/reviews/${id}`,
        data: { ...review },
      }),

      invalidatesTags: (item) => [{ id: item?.id, type: 'ReviewResponses' }],
    }),

    sendPreview: build.mutation<
      { message: string },
      {
        orgId: string
        id: string
        data: {
          email: string
          name: string
        }
      }
    >({
      query: ({ orgId, id, data }) => ({
        method: 'PUT',
        url: `/${orgId}/reviews/${id}/send/preview`,
        data,
      }),
      transformResponse(
        { message: resMessage }: { message: string },
        _1,
        { data }
      ) {
        toast.success(`Preview sent to ${data.email}`)
        return { message: resMessage }
      },
    }),

    downloadResponses: build.query<
      unknown,
      {
        orgId: string
        query: {
          review_text?: string
          review_page?: string
          serial?: string
          review_rating?: number
          review_platforms?: UserReviewPlatforms
          review_sentiment?: ReviewSentimentType
          done?: boolean
          sort_by?: 'asc' | 'desc'
          limit: number
          cursor: string
        }
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'GET',
        url: buildUrl(`/${orgId}/reviews/reports/download`, query),
      }),
      transformResponse(data: string, _1, { orgId }) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `stampede_${orgId}_review_data.csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
    }),

    getSimpleReports: build.query<
      SimpleReviewReportType,
      {
        orgId: string
        query: {
          review?: string
          review_page?: string
          serial?: string
          review_rating?: number
          review_platforms?: UserReviewPlatforms
          review_sentiment?: ReviewSentimentType
          done?: boolean
          start: Date
          end: Date
        }
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'GET',
        url: buildUrl(`/${orgId}/reviews/reports/simple`, {
          ...query,
          start: startOfDay(query.start),
          end: endOfDay(query.end),
        }),
      }),
    }),

    getReports: build.query<
      ReviewReportType,
      {
        orgId: string
        query: {
          review?: string
          review_page?: string
          serial?: string
          review_rating?: number
          review_platforms?: UserReviewPlatforms
          review_sentiment?: ReviewSentimentType
          done?: boolean
          sort?: 'asc' | 'desc'
          groupBy?: 'month' | 'week' | 'day' | 'hour'
          limit?: number
          offset?: number
          start: Date
          end: Date
        }
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'GET',
        url: buildUrl(`/${orgId}/reviews/reports/grouped`, {
          ...query,
          start: startOfDay(query.start),
          end: endOfDay(query.end),
        }),
      }),
    }),

    getEmailReports: build.query<
      {
        sentEmails: number
        totals: {
          sentEmails: number
          redirects: number
          externalRedirects: number
          externalRedirectsPercentage: number
          externalCompleted: number
          externalCompletedPercentage: number
          viaStampedeExternalCompleted: number
          viaStampedeExternalCompletedPercentage: number
        }
        redirects: { count: number; rating: number; percentage: number }[]
        chart: []
      },
      {
        orgId: string
        query: {
          serial?: string
          limit?: number
          offset?: number
          review_settings_ids: string[]
          start: Date
          end: Date
        }
      }
    >({
      query: ({ orgId, query }) => ({
        method: 'GET',
        url: buildUrl(`/${orgId}/reviews/reports/emails`, {
          ...query,
          start: startOfDay(query.start),
          end: endOfDay(query.end),
        }),
      }),
    }),
  }),
})

export default tankApi
