import { OnlineStatusProvider } from '@/connect-context/online-status'
import SegmentProvider from '@/connect-context/tracking/segment'
import theme from '@/connect-theme'
import OauthContext from '@/pages/oauth/context'
import FirebaseProvider from '@/utils/firebase/provider'
import { isInStandaloneMode } from '@/utils/helpers/pwa'
import { ChakraProvider } from '@chakra-ui/react'
import { Analytics } from '@vercel/analytics/react'
import { lazy, Suspense, useEffect } from 'react'
import { Outlet, ScrollRestoration, useNavigate } from 'react-router-dom'
import { Toaster } from 'sonner'
import { BrandKitStyles, buttonVariants, Spinner } from 'tailwind-ui'
import { useColorMode } from '@/hooks/use-color-mode'
import AlertProvider from '@/components/alerts'
import useSaveLastRoute from '@/helpers/router'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { QueryParamProvider } from 'use-query-params'
import qs from 'query-string'
import AppNotifications from '@/components/ProfilePage/AppNotifications'
import { HistoryProvider } from '@/utils/router/history-provider'

const queryStringifyOptions = {
  searchStringToObject: qs.parse,
  objectToSearchString: qs.stringify,
}

const Offline = lazy(() => import('@/common/offline'))

function RootLayout({ children = <Outlet /> }: { children?: React.ReactNode }) {
  useColorMode()

  const push = useNavigate()
  useSaveLastRoute()

  useEffect(() => {
    if (!isInStandaloneMode()) return
    const lastRoute = localStorage.getItem('last-visited-pathname')
    // If we have a saved route, redirect there instead of defaulting to, say, "/"
    if (lastRoute) {
      // Replace the current history entry so the user can still navigate back
      push(lastRoute, { replace: true })
    }
  }, [push])

  return (
    <HistoryProvider>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={queryStringifyOptions}
      >
        <ScrollRestoration />
        <SegmentProvider>
          <OauthContext>
            <Analytics />

            <FirebaseProvider>
              <OnlineStatusProvider>
                <AlertProvider>
                  {'Notification' in window ? <AppNotifications /> : null}

                  <Toaster
                    theme="system"
                    closeButton
                    position="top-right"
                    visibleToasts={3}
                    icons={{ loading: <Spinner size="sm" /> }}
                    toastOptions={{
                      unstyled: true,
                      className: 'z-[9999px]',
                      classNames: {
                        cancelButton:
                          'ml-auto rounded-lg self-start backdrop-blur-none text-xs p-1 font-medium text-white/80 dark:text-black/80',
                        actionButton: buttonVariants({
                          variant: 'primary',
                          brandKit: true,
                          className: 'ml-auto rounded-lg self-start',
                        }),
                        success: 'fill-green-500',
                        description: 'text-xs',

                        closeButton:
                          'self-start !top-4 lg:!-top-2 -left-2 lg:-left-2 border  scale-150 lg:scale-100 border-black/5 dark:border-white/5 !text-black bg-white/95 dark:!text-white dark:bg-black/95 z-[9999px]',
                        title: 'text-sm line-clamp-2',
                        toast:
                          'z-[9999px] duration-250 shadow-lg right-0 items-start insert-0 bg-black/90 dark:bg-white/90 backdrop-blur-lg dark:hover:bg-white hover:bg-black  dark:text-black/90 text-white/90  py-2 pl-6 pr-2 flex items-center gap-x-2 !min-w-80 w-full rounded-xl',
                      },
                    }}
                  />

                  <BrandKitStyles
                    ignoreBody
                    brand={{
                      backgroundImage: '',
                      interfaceColor: '#ff3a9b',
                      roundInputs: true,
                      background: 'white',
                      heading_font_family: 'Montserrat',
                      body_font_family: 'Montserrat',
                    }}
                  />
                  <ChakraProvider theme={theme}>{children}</ChakraProvider>
                  <Suspense fallback={<></>}>
                    <Offline />
                  </Suspense>
                </AlertProvider>
              </OnlineStatusProvider>
            </FirebaseProvider>
          </OauthContext>
        </SegmentProvider>
      </QueryParamProvider>
    </HistoryProvider>
  )
}

export default RootLayout
