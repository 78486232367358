import { type DetailedHTMLProps, forwardRef } from 'react'
import { cx } from 'tailwind-ui'

export const ScrollArea = forwardRef<
  HTMLDivElement,
  DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>(({ className, ...rest }, ref) => {
  return (
    <div
      {...rest}
      ref={ref}
      className={cx(
        'scrollarea scroller min-h-full !overflow-y-auto',
        className
      )}
    />
  )
})
