import { TimeFormats } from '@/common/datepicker/utilities/formats'
import { Alert, useAlertContext } from '@/components/alerts'
import ProfileNav from '@/components/Layout/profile-nav'
import SearchResults from '@/components/profile-search'
import {
  orgNavigationItems,
  profileOrgNavigationItems,
} from '@/components/SideNav/menu-config'
import { generateImage } from '@/helpers/image'
import { usePathname } from '@/helpers/router'
import { useToggleZendesk } from '@/helpers/zendesk'
import {
  type BeforeInstallPromptEvent,
  useAddToHomescreenPrompt,
} from '@/hooks/useAddToHomescreenPrompt'
import { useUserRole } from '@/hooks/useUser'
import useWindowWidth from '@/hooks/useWindowWidth'
import { ShareButton } from '@/providers/user-invite.context'
import { useSelectedVenue } from '@/state/backend/venues/venues.hooks'
import useSelectedOrganisation from '@/state/entities/organisations/hooks/useSelectedOrganisation'
import inboxApi from '@/state/inbox/inbox.slice'
import morpheusApi from '@/state/morpheus/morpheus.slice'
import {
  type UserConfigType,
  useUserConfig,
} from '@/state/morpheus/user-config/helpers'
import { getFirst } from '@/utils/common'
import useDebounce from '@/utils/helpers/debounce'
import { isInStandaloneMode } from '@/utils/helpers/pwa'
import usePrevious from '@/utils/usePrevious'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuSeparator,
} from '@headlessui/react'
import {
  ArrowDownOnSquareIcon,
  ArrowLeftIcon,
  ArrowLongUpIcon,
  ArrowPathIcon,
  ArrowsPointingInIcon,
  ArrowsRightLeftIcon,
  Bars3Icon,
  CheckCircleIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  CogIcon,
  ExclamationCircleIcon,
  HomeIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  ShareIcon,
  ViewfinderCircleIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid'
import { format, parseISO } from 'date-fns'
import {
  type Variants,
  motion,
  useAnimationControls,
  useDragControls,
} from 'framer-motion'
import { throttle } from 'lodash-es'
import {
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import {
  Link,
  NavLink,
  type To,
  matchPath,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from 'react-router-dom'
import { toast } from 'sonner'
import {
  Badge,
  Icn,
  SButton,
  SIconButton,
  StampedeIcon,
  type SvgIcon,
  Tooltip,
  buttonVariants,
  cn,
  cx,
} from 'tailwind-ui'
import { Modal } from 'tailwind-ui/src/modal'
import { useLocalStorage } from 'usehooks-ts'
import { useMenu } from './menu-hook'

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent
  }
}

const menuItemVariant = (isVertical = true): Variants => ({
  open: {
    y: 0,

    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    visibility: 'hidden',
    ///  display: 'none',
    y: 50,
    margin: 0,
    ...(isVertical
      ? {
          height: 0,
        }
      : {
          width: 0,
        }),
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
})

type MainMenuKeyType =
  | 'venues'
  | 'bookings'
  | 'marketing'
  | 'inbox'
  | 'reviews'
  | 'loyalty'
  | 'gift-cards'
  | 'reports'
  | 'wi-fi'
  | 'brand'

const configToTooltipLabelPosition = (position: string) => {
  switch (position) {
    case 'top':
      return 'bottom'
    case 'bottom':
      return 'top'
    case 'left':
      return 'right'
    case 'right':
      return 'left'
    case '':
      return 'right'
  }
}
const MotionNavLink = motion.create(NavLink)

export const defaultButtonClasses =
  'border-0 bg-transparent text-gray-600 dark:text-gray-400 lg:text-gray-100 lg:dark:text-gray-900 hover:bg-black dark:hover:bg-white group-[.active]:bg-black dark:group-[.active]:bg-black dark:focus:bg-neutral-900  focus:bg-neutral-50 focus:text-gray-900  dark:focus:text-gray-50'

interface GetActionBarButtonClassesProps {
  isFixed: boolean
  hasIcon: boolean
  hasLabel: boolean
  isMatch?: boolean
  isVertical?: boolean
}

function getActionBarButtonClasses({
  isFixed,
  hasIcon,
  hasLabel,
  isMatch,
  isVertical,
}: GetActionBarButtonClassesProps) {
  return [
    'relative h-auto h-fit group',
    'group group-[.dragging]:pointer-events-none',
    'transition-colors duration-250',
    'outline-0 focus:outline-none hover:text-stampede-500 aria-[current=page]:text-neutral-50 md:aria-[current=page]:bg-stampede-500/75  md:dark:aria-[current=page]:bg-stampede-500/75',
    {
      'text-center': isFixed && !isVertical,
    },
    {
      'text-white/60 dark:text-black/60': !isFixed,
      ' rounded-xl': isFixed && hasIcon,
      'text-black/60 dark:text-white/60 hover:text-stampede-500 dark:hover:text-stampede-500':
        isFixed,
    },

    {
      'size-12': !hasLabel && isFixed,
      'size-12 rounded-full': !hasLabel && !isFixed,
      'p-2 flex gap-1 items-center w-full': hasLabel,
      'flex max-lg:flex-col items-center text-center text-[0.625rem]':
        hasIcon && hasLabel,
      'flex items-center justify-center': hasIcon && !hasLabel,
      'text-left   dark:hover:bg-white/5 rounded-md px-4': !hasIcon && hasLabel,
      'justify-center': !isVertical || (!isFixed && isVertical),
      //'justify-center mt-auto': isVertical && !isFixed,
      'justify-start': isVertical && !isFixed && hasLabel,
    },
  ]
}

function ActionBarItemPure({
  name = '',
  onClick,
  icon,
  to,
  id,
  count,

  className = '',
}: {
  name: string
  onClick?: () => void
  icon: SvgIcon
  id: string
  to?: To
  count?: number

  className: string
}) {
  const config = useUserConfig()
  const { pathname } = useLocation()
  const { serial } = useParams<{ serial: string }>()
  const variants = useMemo(() => {
    return menuItemVariant(config.action_bar.vertical)
  }, [config.action_bar.vertical])

  const isMatch = useMemo(() => {
    const isVenuePath = pathname.includes('/venues')
    return matchPath(
      pathname
        .split('/')
        .slice(0, isVenuePath ? 5 : 3)
        .join('/'),
      to?.toString() ?? ''
    )
  }, [to, pathname])

  const isVenuePath = (to ?? '').includes(serial ?? 'no-venue-here')

  const isFixed = config.action_bar.is_fixed

  const buttonClasses = getActionBarButtonClasses({
    isFixed: config.action_bar.is_fixed,
    hasIcon: config.action_bar.icon,
    hasLabel: config.action_bar.label,
    isMatch,
    isVertical: config.action_bar.vertical,
  })

  const isThisPathCurrentlyActive = useMemo(() => {
    return pathname.includes(to?.toString() ?? '')
  }, [to, pathname])

  return (
    <Tooltip
      className={cn({
        'order-last': isVenuePath,
      })}
      side={configToTooltipLabelPosition(config.action_bar.position)}
      information={!config.action_bar.label && name}
    >
      <MotionNavLink
        variants={!isMatch ? variants : {}}
        style={{ touchAction: 'none' }}
        className={cn(
          buttonClasses,
          'relative  text-neutral-800 dark:text-neutral-100 hover:text-stampede-500',
          {
            'md:bg-stampede-500/80 text-stampede-500 dark:text-stampede-500  md:text-neutral-100 dark:md:text-neutral-100 md:hover:text-white md:dark:hover:text-black':
              isThisPathCurrentlyActive && to,
          },
          'bg-transparent '
        )}
        {...(to ? { to } : { to: '', end: true })}
        {...(Boolean(onClick) && {
          onClick: (e) => {
            e.preventDefault()
            onClick?.()
          },
        })}
      >
        <div
          className={cn(
            'p-0 max-md:size-3 max-md:hidden self-center duration-1000 max-md:left-4 transition-all ease-in-out size-2 left-1 -mt-1 !top-1/2 absolute  flex items-center justify-center  rounded-full inset-0',
            {
              'opacity-0 -left-5': isVenuePath || Boolean(onClick) || !serial,
              'size-1 -mt-0.5': !config.action_bar.label,
            }
          )}
        >
          {config.action_bar.label ? (
            <Icn icon={ChevronLeftIcon} size="sm" className="" />
          ) : null}
        </div>

        {count && count > 0 ? (
          <Badge
            className="absolute border-0 top-0.5 -right-1 lg:-top-0.5 lg:-right-0.5   z-10 p-0.5 truncate min-w-[22px] !bg-red-500 group-hover:!bg-red-600 text-white  font-light"
            variant="secondary"
          >
            {count >= 99 ? '99+' : count}
          </Badge>
        ) : null}
        {config.action_bar.icon ? (
          <Icn size="lg" className="size-6" icon={icon} />
        ) : null}
        {config.action_bar.label ? (
          <span className="max-md:!text-[9px] text-base leading-tight font-medium">
            {name}
          </span>
        ) : null}
      </MotionNavLink>
    </Tooltip>
  )
}

const ActionBarItem = memo(ActionBarItemPure)

function NavItems({
  toInclude = [
    'venues',
    'marketing',
    'inbox',
    'reviews',
    'loyalty',
    'gift-cards',
    'reports',
    'bookings',
    'wi-fi',
    // 'brand',
  ],
}: {
  toInclude?: Partial<MainMenuKeyType>[]
}) {
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false)
  const { width } = useWindowWidth()

  // const size = useBreakpointValue({ base: 'md', lg: 'md' })
  const organisation = useSelectedOrganisation()

  const role = useUserRole()
  const pathname = usePathname()
  const { serial } = useParams<{ serial: string }>()
  const config = useUserConfig()
  const { data: selectedVenue, isLoading } = useSelectedVenue(serial)
  const { data: unreadCount = { open: 0, closed: 0 } } =
    inboxApi.useGetUnreadCountQuery(
      {
        orgId: organisation?.id!,
        serial,
      },
      {
        skip: !organisation?.id,
      }
    )

  /*
  const toIncludeWithAuth = useMemo(() => {
    if (!role) return []
    switch (role?.key) {
      case 'marketeer':
        return toInclude.filter(
          (item) => !['gift-cards', 'venues', 'gift-cards'].includes(item)
        )
      case 'booking':
        return toInclude.filter(
          (item) =>
            !['gift-cards', 'loyalty', 'marketing', 'brand'].includes(item)
        )
      case 'reports':
        return toInclude.filter(
          (item) =>
            ![
              'marketing',
              'gift-cards',
              'inbox',
              'venues',
              'reviews',
              'loyalty',
              'brand',
            ].includes(item)
        )
      case 'moderator':
        return toInclude.filter(
          (item) =>
            !['marketing', 'gift-cards', 'reports', 'wi-fi'].includes(item)
        )
      case 'admin':
        return toInclude
      case 'super':
        return toInclude
      case 'reseller':
        return toInclude.filter(
          (item) =>
            ![
              'marketing',
              'gift-cards',
              'inbox',
              'reviews',
              'loyalty',
              'brand',
            ].includes(item)
        )
    }
  }, [toInclude, role])
*/
  const isVenuePath = pathname.includes('venues')

  const [shadowVenue, setShadowVenue] = useLocalStorage(
    `active-menu-venue-${organisation?.id}`,
    selectedVenue
  )

  const isMobile = width < 768
  // const MAX_MOBILE_ITEMS = shadowVenue ? 3 : 4

  useEffect(() => {
    if (!selectedVenue) return
    setShadowVenue(selectedVenue)
  }, [selectedVenue])

  /*
  const { data: wifiConfigs = [] } = wifi.useGetConfigsQuery({
    orgId: organisation?.id!,
  })
  */

  const menuItems = useMenu({
    toInclude: [...toInclude, 'brand'],
  })

  const buttonClasses = getActionBarButtonClasses({
    isFixed: config.action_bar.is_fixed,
    hasIcon: config.action_bar.icon,
    hasLabel: config.action_bar.label,
    isMatch: isVenuePath,
    isVertical: config.action_bar.vertical,
  })

  const isVenueActive = pathname.includes('/venues/')

  return (
    <>
      {organisation?.locations.length > 1 && shadowVenue ? (
        <Menu>
          <MenuButton
            className={cn(
              buttonClasses,
              'transition-colors h-12 w-full gap-0  md:mb-1  relative md:max-w-44 flex  duration-200 overflow-hidden  ',
              {
                'rounded-xl w-12 items-center justify-center':
                  !config.action_bar.label,
                'rounded-md': config.action_bar.label,
                //'bg-black/20 dark:bg-white/20': Boolean(serial),
                'md:bg-stampede-500/30 ': isVenueActive,
                'h-8': config.action_bar.label,
                'rounded-full':
                  !config.action_bar.is_fixed && !config.action_bar.label,
              }
            )}
          >
            {config.action_bar.icon ? (
              <>
                <div className="absolute md:size-12 inset-0 z-0 max-md:hidden ">
                  {shadowVenue.branding_settings?.backgroundImage &&
                  !shadowVenue.branding_settings?.backgroundImage.endsWith(
                    '.mp4'
                  ) ? (
                    <img
                      src={generateImage(
                        shadowVenue.branding_settings?.backgroundImage,
                        'avatar'
                      )}
                      className="w-full h-full object-cover"
                    />
                  ) : null}
                  <div
                    className={cn(
                      'absolute  inset-0 bg-gradient-to-b from-white/30 to-white/90 dark:from-black/20 dark:to-black/90',
                      {
                        'from-stampede-500/30 to-stampede-500/90 dark:from-stampede-900/50 dark:to-stampede-900/100':
                          isVenueActive,
                      }
                    )}
                  />
                </div>
                <span
                  className={cn('text-xl font-extrabold relative ', {
                    'text-white dark:text-white': isVenueActive,
                  })}
                >
                  {(shadowVenue.alias ?? selectedVenue.serial)
                    .charAt(0)
                    .toUpperCase() +
                    (shadowVenue.alias ?? selectedVenue.serial)
                      .charAt(1)
                      .toUpperCase()}
                </span>
              </>
            ) : null}
            {config.action_bar.label ? (
              <span
                className={cn(
                  'font-medium truncate',
                  isVenueActive ? 'text-white dark:text-white' : ''
                )}
              >
                {(shadowVenue.alias ?? shadowVenue.serial)
                  .charAt(0)
                  .toUpperCase() +
                  (shadowVenue.alias ?? shadowVenue.serial).slice(1)}
              </span>
            ) : null}
          </MenuButton>

          <MenuItems
            portal
            transition
            anchor="left start"
            className="w-52 z-50 ml-3 fixed origin-top-left rounded-xl border border-black/10 dark:border-white/10 bg-white/50 dark:bg-black/50  p-1 focus:outline-none space-y-0.5 backdrop-blur-lg"
          >
            {menuItems.venue.map((item) => (
              <MenuItem
                key={item.id}
                as={NavLink}
                to={item.to}
                className={cn(
                  'group flex text-md w-full   items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 aria-[current=page]:bg-black/10 dark:aria-[current=page]:bg-white/10'
                )}
              >
                {item.icon ? (
                  <Icn
                    icon={item.icon}
                    className="size-4 fill-black/30 dark:fill-white/30"
                  />
                ) : null}
                <span className="truncate">{item.name}</span>
              </MenuItem>
            ))}
            <MenuSeparator />
            <MenuItem
              as={NavLink}
              to={`/${organisation?.id}/venues`}
              className={cn(
                'group flex text-md w-full  items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 aria-[current=page]:bg-black/10 dark:aria-[current=page]:bg-white/10'
              )}
            >
              <Icn
                icon={ArrowsRightLeftIcon}
                className="size-4 fill-black/30 dark:fill-white/30"
              />
              <span>Switch Venue</span>
            </MenuItem>
          </MenuItems>
        </Menu>
      ) : null}
      {menuItems.visible
        .filter((item) => item.id !== 'brand')
        .map((item) => (
          <ActionBarItem key={item.id} {...item} />
        ))}

      {isMobile && menuItems.overflow.length > 0 ? (
        <>
          <ActionBarItem
            name="More"
            icon={Bars3Icon}
            id="more-menu"
            onClick={() => {
              setIsMoreMenuOpen(true)
            }}
          />

          <Dialog
            open={isMoreMenuOpen}
            onClose={() => {
              setIsMoreMenuOpen(false)
            }}
            className="relative z-50"
          >
            <DialogBackdrop
              transition
              className="fixed inset-0 bg-white/80 dark:bg-black/80 backdrop-blur-lg duration-500 transition-all data-[closed]:translate-y-full"
              aria-hidden="true"
            />

            <div className="fixed inset-0 flex items-end justify-center">
              <DialogPanel className="w-full py-4  h-full rounded-t-xl relative overflow-y-auto">
                <motion.div
                  variants={closeButtonVariants}
                  initial="closed"
                  className="absolute right-2 top-2 z-10"
                  animate={isMoreMenuOpen ? 'open' : 'closed'}
                >
                  <SIconButton
                    icon={XMarkIcon}
                    onClick={() => {
                      setIsMoreMenuOpen(false)
                    }}
                    isRound
                    size="lg"
                    variant="ghost_default"
                  />
                </motion.div>

                <motion.div
                  className="space-y-1  px-4"
                  initial="closed"
                  animate={isMoreMenuOpen ? 'open' : 'closed'}
                  variants={moreMenuVariants}
                >
                  {/* Navigation Items */}

                  <motion.div
                    variants={titleVariants}
                    className="text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider py-2"
                  >
                    Navigation
                  </motion.div>
                  {menuItems.overflow.map((item) => (
                    <motion.div key={item.id} variants={moreMenuItemVariants}>
                      <NavLink
                        to={item.to}
                        onClick={() => {
                          setIsMoreMenuOpen(false)
                          item.onClick?.()
                        }}
                        className={cn(
                          'group flex text-md w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 aria-[current=page]:bg-black/10 dark:aria-[current=page]:bg-white/10'
                        )}
                      >
                        {item.icon ? (
                          <Icn
                            icon={item.icon}
                            className="size-4 fill-black/30 dark:fill-white/30"
                          />
                        ) : null}
                        <span>{item.name}</span>
                      </NavLink>
                    </motion.div>
                  ))}

                  {/* Profile Items */}
                  <div className="h-px bg-white/5 my-1" />
                  <motion.div
                    variants={titleVariants}
                    className="text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider py-2"
                  >
                    Profile
                  </motion.div>

                  {profileOrgNavigationItems.map((item) => (
                    <motion.div key={item.key} variants={moreMenuItemVariants}>
                      <NavLink
                        to={`/${organisation?.id}/${item.key}`}
                        onClick={() => {
                          setIsMoreMenuOpen(false)
                        }}
                        className={cn(
                          'group flex text-sm/6 w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 aria-[current=page]:bg-black/10 dark:aria-[current=page]:bg-white/10'
                        )}
                      >
                        <Icn
                          icon={item.icon}
                          size="lg"
                          className="size-4 fill-black/30 dark:fill-white/30"
                        />
                        <span className="text-gray-900 dark:text-gray-100">
                          {item.title}
                        </span>
                      </NavLink>
                    </motion.div>
                  ))}

                  {/* Organization Items */}
                  <div className="h-px bg-white/5 my-1" />
                  <motion.div
                    variants={titleVariants}
                    className="text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider py-2"
                  >
                    Organization
                  </motion.div>
                  {orgNavigationItems.map((item) => (
                    <motion.div key={item.key} variants={moreMenuItemVariants}>
                      <NavLink
                        to={
                          item.key === '/'
                            ? '/'
                            : `/${organisation?.id}/${item.key}`
                        }
                        onClick={() => {
                          setIsMoreMenuOpen(false)
                        }}
                        className={cn(
                          'group flex text-sm/6 w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 aria-[current=page]:bg-black/10 dark:aria-[current=page]:bg-white/10'
                        )}
                      >
                        <Icn
                          icon={item.icon}
                          size="lg"
                          className="size-4 fill-black/30 dark:fill-white/30"
                        />
                        <span className="text-gray-900 dark:text-gray-100">
                          {item.title}
                        </span>
                      </NavLink>
                    </motion.div>
                  ))}

                  {/* Account Items */}

                  <motion.div variants={moreMenuItemVariants}>
                    <Link
                      to="/logout"
                      onClick={() => {
                        setIsMoreMenuOpen(false)
                      }}
                      className={cn(
                        'group flex text-sm/6 w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10'
                      )}
                    >
                      <Icn
                        icon={ArrowLeftIcon}
                        size="lg"
                        className="size-4 fill-black/30 dark:fill-white/30"
                      />
                      <span className="text-gray-900 dark:text-gray-100">
                        Logout
                      </span>
                    </Link>
                  </motion.div>
                  {isInStandaloneMode() ? (
                    <motion.div variants={moreMenuItemVariants}>
                      <button
                        onClick={() => {
                          setIsMoreMenuOpen(false)
                        }}
                        className={cn(
                          'group flex text-sm/6 w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 aria-[current=page]:bg-black/10 dark:aria-[current=page]:bg-white/10'
                        )}
                      >
                        <Icn
                          icon={ArrowPathIcon}
                          size="lg"
                          className="size-4 fill-black/30 dark:fill-white/30"
                        />
                        <span className="text-gray-900 dark:text-gray-100">
                          Reload App
                        </span>
                      </button>
                    </motion.div>
                  ) : null}
                  <div className="h-px bg-white/5 my-1" />

                  <motion.div
                    className="group flex  w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                    variants={moreMenuItemVariants}
                  >
                    <p className="text-xs text-gray-900 dark:text-gray-100">
                      Build Time{' '}
                      {format(
                        __DATE__,
                        TimeFormats.StandardDateMonthYearSmallHHmm
                      )}
                    </p>
                  </motion.div>
                </motion.div>
              </DialogPanel>
            </div>
          </Dialog>
        </>
      ) : null}
    </>
  )
}

const dragConstraintsFromPosition = (
  position: string,
  { width, height }: { width: number; height: number },
  dimensions: { width: number; height: number }
) => {
  const actionHelperSize = 42
  const iconHeight = 40
  const { width: floatingWidth, height: floatingHeight } = dimensions

  switch (position) {
    case 'top':
      return {
        left: -(width / 2) + floatingWidth / 2,
        right: width / 2 - floatingWidth / 2,
        bottom: height - (actionHelperSize + iconHeight),
        top: 0 - actionHelperSize,
      }
    case 'bottom':
      return {
        left: -(width / 2) + floatingWidth / 2,
        right: width / 2 - floatingWidth / 2,
        top: -(height - (actionHelperSize + iconHeight)),
        bottom: 0 + actionHelperSize,
      }
    case 'left':
      return {
        right: width - (floatingWidth + actionHelperSize),
        top: -(height / 2) + floatingHeight / 2,
        bottom: height / 2 - floatingHeight / 2,
        left: 0 - actionHelperSize,
      }
    case 'right':
      return {
        left: -(width - floatingWidth * 2 + actionHelperSize * 2),
        top: -(height / 2) + floatingHeight / 2,
        bottom: height / 2 - floatingHeight / 2,
        right: 0 + actionHelperSize,
      }
  }
}

const getYFromPosition = (pos: string) => {
  switch (pos) {
    case 'bottom':
      return { x: 0, y: 40 }
    case 'top':
      return { x: 0, y: -40 }
    case 'left':
      return { x: -100, y: 0 }
    case 'right':
      return { x: 100, y: 0 }
  }
}

const moreMenuVariants = {
  open: {
    clipPath: 'inset(0% 0% 0% 0% round 10px)',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.7,
      delayChildren: 0.5,
      staggerChildren: 0.05,
    },
  },
  closed: {
    clipPath: 'inset(10% 50% 90% 50% round 10px)',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.3,
    },
  },
}

const moreMenuItemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 24,
    },
  },
  closed: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 0.2,
    },
  },
}

const transformButtonVariants = {
  open: {
    rotate: 0,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 20,
    },
  },
  closed: {
    rotate: 45,
  },
}

const closeButtonVariants = {
  open: {
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 20,
    },
  },
  closed: {
    scale: 0,
  },
}

const titleVariants = {
  open: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 24,
    },
  },
  closed: {
    opacity: 0,
    x: -100,
    transition: {
      duration: 0.2,
    },
  },
}

export function ActionBarSettingsForm({
  config,
  onComplete,
}: {
  config: UserConfigType
  onComplete: () => void
}) {
  const { handleSubmit, register, reset, setValue, watch } =
    useForm<UserConfigType>({
      defaultValues: config,
    })

  useEffect(() => {
    reset(config)
  }, [config])

  const position = watch('action_bar.position')
  const label = watch('action_bar.label')
  const icons = watch('action_bar.icon')
  const isFixed = watch('action_bar.is_fixed')

  const [updateConfig, { isLoading: isConfigUpdateLoading }] =
    morpheusApi.useUpdateUserConfigMutation()

  const onSubmit: SubmitHandler<UserConfigType> = async (data) => {
    return toast.promise(
      updateConfig({
        ...data,
        action_bar: {
          ...data.action_bar,
          position: !data.action_bar.position
            ? 'left'
            : data.action_bar.position,
        },
      }).unwrap(),
      {
        loading: 'Saving...',
        success: () => {
          onComplete()
          return 'Saved'
        },
        error: 'Failed to save',
      }
    )
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-3 max-lg:pb-24"
    >
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="bg-green-100 lg:max-w-72	 dark:bg-green-800 border dark:border-green-900 border-green-200 rounded-xl p-4 flex space-y-4 flex-col">
          <CogIcon className="w-12 h-12  duration-1000 ease-linear animate-[wiggle_1s_ease-in-out_infinite]	 fill-green-500 dark:fill-green-100" />
          <div>
            <p className="text-green-900 dark:text-green-100 text-sm font-semibold">
              Menu Update
            </p>
            <div className="space-y-3">
              <p className="text-green-900 text-sm dark:text-green-100">
                As Stampede's feature set is now being widely used by customers
                who are taking advantage of our entire ecosystem of products we
                had to create a more efficient way of navigating around the
                platform.
              </p>
              <p className="text-green-900 text-sm dark:text-green-100">
                You can now quickly flick through any product or feature by
                using the action bar. This will allow you to quickly navigate
                between venues, bookings, marketing, inbox, reviews, loyalty,
                gift cards and reports.
              </p>
              <p className="text-green-900 text-sm dark:text-green-100">
                This interface now works seamlessly across all devices. Mobile,
                tablet and desktop.
              </p>
              <p className="text-green-900 text-sm dark:text-green-100">
                You can currently customise the style and position of the action
                bar, if it ever gets in the way try giving it a drag 🏎️.
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col w-full space-y-4">
          <fieldset>
            <div className="bg-white dark:bg-black border rounded-xl p-4 flex flex-col space-y-4">
              <legend className="text-sm font-semibold dark:text-neutral-200 text-gray-900">
                Menu Items
              </legend>
              <div className="gap-6 items-end justify-evenly flex ">
                <div className="flex flex-col gap-4 items-center">
                  <SIconButton
                    icon={HomeIcon}
                    size="lg"
                    isActive={icons && !label}
                    isRound
                    onClick={() => {
                      setValue('action_bar.icon', true)
                      setValue('action_bar.label', false)
                    }}
                  />
                  <div
                    className={cx('h-4 w-4 rounded-full', {
                      'bg-green-500': icons,
                      'bg-yellow-500': label,
                    })}
                  />
                </div>
                <div className="flex flex-col gap-4 items-center">
                  <SButton
                    size="lg"
                    isActive={!icons && label}
                    onClick={() => {
                      setValue('action_bar.icon', false)
                      setValue('action_bar.label', true)
                    }}
                  >
                    Home
                  </SButton>
                  <div
                    className={cx('h-4 w-4 rounded-full', {
                      'bg-green-500': !icons,
                      'bg-yellow-500': !label,
                    })}
                  />
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <div className="bg-white dark:bg-black border rounded-xl p-4 flex flex-col space-y-4">
              <legend className="text-sm font-semibold dark:text-neutral-200 text-gray-900">
                Floating Menu
              </legend>
              <div className="gap-6 items-end justify-evenly flex ">
                <div className="flex flex-col gap-4 items-center">
                  <SButton
                    size="lg"
                    isActive={isFixed}
                    onClick={() => {
                      setValue('action_bar.is_fixed', true)
                    }}
                  >
                    Fixed
                  </SButton>
                  <div
                    className={cx('h-4 w-4 rounded-full', {
                      'bg-green-500': isFixed,
                      'bg-yellow-500': !isFixed,
                    })}
                  />
                </div>
                <div className="flex flex-col gap-4 items-center">
                  <SButton
                    size="lg"
                    isActive={!isFixed}
                    onClick={() => {
                      setValue('action_bar.is_fixed', false)
                    }}
                  >
                    Floating
                  </SButton>
                  <div
                    className={cx('h-4 w-4 rounded-full', {
                      'bg-green-500': !isFixed,
                      'bg-yellow-500': isFixed,
                    })}
                  />
                </div>
              </div>
            </div>
          </fieldset>

          <div className="flex flex-col justify-center gap-16 bg-white dark:bg-black rounded-xl border p-4">
            <div className="flex flex-col gap-2 justify-center">
              <legend className="text-sm font-semibold dark:text-neutral-200 text-gray-900">
                Menu Position
              </legend>
              <SIconButton
                size="lg"
                onClick={() => {
                  setValue('action_bar.position', 'top')
                }}
                icon={ArrowLongUpIcon}
                className="self-center"
                isActive={position === 'top'}
                isRound
              />
            </div>

            <div>
              <div className="flex justify-between items-center">
                <SIconButton
                  size="lg"
                  onClick={() => {
                    setValue('action_bar.position', 'left')
                  }}
                  icon={ArrowLongUpIcon}
                  classNames={{
                    icon: '-rotate-90',
                  }}
                  isActive={position === 'left'}
                  isRound
                />
                <motion.div
                  className="relative"
                  animate={getYFromPosition(position)}
                  transition={{ ease: 'easeOut', duration: 1 }}
                >
                  <StampedeIcon
                    className={cn(
                      'w-16 h-16 fill-neutral-800 dark:fill-neutral-200 '
                    )}
                  />
                </motion.div>
                <SIconButton
                  size="lg"
                  isRound
                  classNames={{
                    icon: 'rotate-90',
                  }}
                  onClick={() => {
                    setValue('action_bar.position', 'right')
                  }}
                  icon={ArrowLongUpIcon}
                  isActive={position === 'right'}
                />
              </div>
            </div>
            <SIconButton
              size="lg"
              classNames={{
                icon: 'rotate-180',
              }}
              onClick={() => {
                setValue('action_bar.position', 'bottom')
              }}
              icon={ArrowLongUpIcon}
              className="self-center"
              isActive={position === 'bottom'}
              isRound
            />
          </div>

          <div className="flex justify-end pt-6">
            <SButton
              variant="primary"
              type="submit"
              size="lg"
              className="rounded-full border-0 px-6"
              isLoading={isConfigUpdateLoading}
            >
              Save
            </SButton>
          </div>
        </div>
      </div>
    </form>
  )
}

export function ActionBar() {
  const { org_id: orgId } = useParams<{ org_id: string }>()
  //const bg = useColorModeValue('white', 'gray.900')
  const { state } = useNavigation()
  const [term, setTerm] = useState('')
  const search = useDebounce(term, 600)
  const { pathname } = useLocation()
  const [searchVisible, setSearchVisible] = useState(false)
  const organisation = useSelectedOrganisation()
  const [alerts] = useAlertContext()
  const [prompt, promptToInstall] = useAddToHomescreenPrompt()
  useEffect(() => {
    setSearchVisible(false)
  }, [pathname, setSearchVisible])

  const variants: Variants = {
    open: {
      transition: {
        staggerChildren: 0.07,
        delayChildren: 0.1,
      },
    },
    closed: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
        delayChildren: 0.1,
      },
    },
  }

  const controls = useDragControls()
  const animationControls = useAnimationControls()
  const [isEditOpen, setIsEditOpen] = useState(false)
  const config = useUserConfig()
  const navigate = useNavigate()
  const floatingRef = useRef<HTMLDivElement | null>(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const [isDragging, setIsDragging] = useState(false)
  const [alertsIsOpen, setAlertsIsOpen] = useState(false)

  useLayoutEffect(() => {
    setAlertsIsOpen(false)
  }, [pathname])

  useEffect(() => {
    if (alerts.filter((item) => !item.isDismissed).length > 0) return
    setAlertsIsOpen(false)
  }, [alerts])

  useLayoutEffect(() => {
    if (!config.isSuccess) return

    setIsEditOpen(!config.action_bar.has_updated)
  }, [config.isSuccess, config.action_bar.has_updated])

  const { width, height } = useWindowWidth()
  const prevWidth = usePrevious(width)

  const [, toggle] = useToggleZendesk(width > 768)

  useLayoutEffect(() => {
    setTimeout(() => {
      toggle(width > 768)
    }, 250)
  }, [width, toggle])

  useEffect(() => {
    if (!prevWidth) return
    if (!width) return
    if (prevWidth === width) return
    const timer = setTimeout(() => {
      animationControls.start({
        x: 0,
        y: 0,
      })
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [width, prevWidth, animationControls])

  useEffect(() => {
    if (!config) return
    const timer = setTimeout(() => {
      animationControls.start({
        x: 0,
        y: 0,
      })
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [config, animationControls])

  useLayoutEffect(() => {
    if (config.action_bar.position !== 'left') return
    if (config.action_bar.is_fixed) return

    const timer = setTimeout(() => {
      if (document.body.classList.contains('page-has-side-nav')) {
        animationControls.start({
          x: (width > 1280 ? 320 : 320) - floatingRef.current?.clientWidth - 12,
          y: 40,
          transition: {
            duration: 0.75,
          },
        })
      } else {
        animationControls.start({
          x: 0,
          y: 0,
          transition: {
            duration: 0.5,
          },
        })
      }
    }, 1250)

    return () => {
      clearTimeout(timer)
    }
  }, [
    pathname,
    config.action_bar.position,
    config.action_bar.is_fixed,
    animationControls,
    width,
  ])

  const resizeObserver = useRef<ResizeObserver | null>(null)

  useEffect(() => {
    if (isDragging) return
    if (config.action_bar.is_fixed) return
    if (floatingRef.current) {
      resizeObserver.current = new ResizeObserver(
        throttle((entries) => {
          const outerPadding = 18
          const observedHeight =
            getFirst(entries).contentRect.height + outerPadding
          const ovservedWidth =
            getFirst(entries).contentRect.width + outerPadding
          setDimensions({ height: observedHeight, width: ovservedWidth })
        }, 1000)
      )

      resizeObserver.current.observe(floatingRef.current)

      return () => {
        if (resizeObserver.current) {
          // Cleanup the observer when the component is unmounted
          resizeObserver.current.disconnect()
        }
      }
    }
  }, [isDragging, config.action_bar.is_fixed])

  const push = useNavigate()

  const AlertsToIcon = useMemo(() => {
    //apply sorting
    const icons = alerts.map((item) => {
      switch (item.type) {
        case 'info':
          return InformationCircleIcon
        case 'success':
          return CheckCircleIcon
        case 'warning':
          return ExclamationCircleIcon
        case 'error':
          return XMarkIcon
      }
    })
    return getFirst(icons)
  }, [alerts])

  const AlertsToClassName = useMemo(() => {
    //apply sorting
    const icons = alerts.map((item) => {
      switch (item.type) {
        case 'info':
          return '!bg-blue-600'
        case 'success':
          return '!bg-green-600'
        case 'warning':
          return '!bg-orange-600'
        case 'error':
          return '!bg-red-600'
      }
    })
    return getFirst(icons)
  }, [alerts])

  return (
    <>
      <Modal
        title="Guest Search"
        isOpen={searchVisible}
        onClose={() => {
          setSearchVisible(false)
        }}
        classNames={{
          body: 'pb-2',
        }}
      >
        <SearchResults />
      </Modal>

      <Modal
        title="Customise menu"
        isOpen={isEditOpen}
        onClose={() => {
          setIsEditOpen(false)
        }}
        size="xl"
      >
        <ActionBarSettingsForm
          onComplete={() => setIsEditOpen(false)}
          config={config}
        />
      </Modal>
      <Modal
        isOpen={alertsIsOpen}
        onClose={() => {
          setAlertsIsOpen(false)
        }}
      >
        {alerts.length >= 1 && (
          <motion.div
            variants={variants}
            className="flex flex-col gap-2 p-2 overflow-y-auto "
          >
            {(alerts ?? []).map((alert) => (
              <Alert key={alert.itemKey} {...alert} />
            ))}
          </motion.div>
        )}
      </Modal>
      <motion.div
        ref={resizeObserver}
        className={cn('flex-1 flex max-md:pb-safe h-fit', {
          'bottom-0 right-1/2 translate-x-[50%]':
            config.action_bar.position === 'bottom',
          'top-0 right-1/2 translate-x-[50%]':
            config.action_bar.position === 'top',
          'left-0 top-1/2 translate-y-[-50%]':
            config.action_bar.position === 'left',
          'right-0 top-1/2 translate-y-[-50%]':
            config.action_bar.position === 'right',
          'flex-col-reverse':
            !config.action_bar.vertical &&
            config.action_bar.position === 'bottom',

          'flex-row-reverse':
            config.action_bar.vertical &&
            config.action_bar.position === 'right',
          'flex-col': config.action_bar.position === 'top',
          'z-[99] fixed': isEditOpen,
          'z-50 absolute ': !isEditOpen && !config.action_bar.is_fixed,
          'flex-col-reverse justify-between min-h-full':
            config.action_bar.is_fixed &&
            ['left', 'right'].includes(config.action_bar.position),
          'items-center': !config.action_bar.is_fixed,
          '!translate-y-0 !translate-x-0': config.action_bar.is_fixed,
        })}
      >
        <div
          className={cn(
            'flex gap-0.5  items-center justify-evenly lg:justify-center max-lg:hidden  bg-black/10 dark:bg-white/10 ',
            {
              'flex-col': config.action_bar.vertical,
              'rounded-full ': !config.action_bar.is_fixed,
              'rounded-lg m-1': config.action_bar.is_fixed,
              'py-2': config.action_bar.is_fixed && config.action_bar.vertical,
              'px-0.5':
                config.action_bar.vertical && !config.action_bar.is_fixed,
              'self-end':
                config.action_bar.is_fixed &&
                ['top'].includes(config.action_bar.position),
              'self-start':
                config.action_bar.is_fixed &&
                ['bottom'].includes(config.action_bar.position),
              'mt-auto ':
                config.action_bar.vertical && config.action_bar.is_fixed,
            }
          )}
        >
          <Tooltip
            information={`${organisation?.name} Home`}
            side={configToTooltipLabelPosition(config.action_bar.position)}
          >
            <SIconButton
              size="xs"
              variant="ghost_default"
              isRound
              icon={HomeIcon}
              isActive={pathname === `/${orgId}`}
              onClick={() => {
                navigate('')
              }}
            />
          </Tooltip>

          <>
            {width > 1023 ? (
              <Tooltip
                information="Edit action bar"
                side={configToTooltipLabelPosition(config.action_bar.position)}
              >
                <SIconButton
                  size="xs"
                  variant="ghost_default"
                  isRound
                  icon={CogIcon}
                  isActive={isEditOpen}
                  onClick={(ev) => {
                    setIsEditOpen((s) => !s)
                    animationControls.start({
                      x: 0,
                      y: 0,
                    })
                  }}
                />
              </Tooltip>
            ) : (
              <SIconButton
                size="xs"
                variant="ghost_default"
                isRound
                icon={ArrowsPointingInIcon}
                onClick={() => {
                  animationControls.start({
                    x: 0,
                    y: 0,
                  })
                }}
              />
            )}
          </>

          <>
            <Tooltip
              information="Search customers"
              side={configToTooltipLabelPosition(config.action_bar.position)}
            >
              <SIconButton
                size="xs"
                aria-label="search customers"
                icon={MagnifyingGlassIcon}
                onClick={() => {
                  setSearchVisible(true)
                }}
                variant="ghost_default"
                isRound
              />
            </Tooltip>
            <ShareButton
              size="xs"
              className="border-0 shadow-lg "
              isRound
              variant="ghost_default"
              icon={ShareIcon}
            />
          </>

          {pathname !== `/${orgId}` && (
            <SIconButton
              size="xs"
              aria-label="go back"
              icon={ArrowLeftIcon}
              onClick={() => {
                push(-1)
              }}
              variant="ghost_default"
              isRound
            />
          )}
        </div>

        <motion.div
          className={cx('flex items-center justify-center  space-y-2 z-50', {
            'bottom-0': config.action_bar.position === 'bottom',
            'top-7 ': config.action_bar.position === 'top',
            'left-7 flex-col': config.action_bar.position === 'left',
            'right-7 flex-col': config.action_bar.position === 'right',
            ' absolute': !config.action_bar.is_fixed,
          })}
          animate={animationControls}
          drag={!config.action_bar.is_fixed}
          style={{
            touchAction: 'none',
            //pointerEvents: isOpen ? 'auto' : 'none',
          }}
          dragConstraints={dragConstraintsFromPosition(
            config.action_bar.position,
            { width, height },
            dimensions
          )}
          dragTransition={{ bounceStiffness: 500, bounceDamping: 10 }}
          dragElastic={0.15}
          dragControls={controls}
          onDragStart={() => {
            setIsDragging(true)
          }}
          onDragEnd={() => {
            setIsDragging(false)
          }}
        >
          <motion.div
            ref={floatingRef}
            variants={variants}
            className={cx(
              'my-auto w-full grid grid-cols-5 md:flex  items-center ',
              {
                'justify-evenly md:justify-center max-lg:px-4':
                  config.action_bar.label && config.action_bar.icon,
                'justify-evenly  items-center': !config.action_bar.label,
                'flex-col': config.action_bar.vertical,
                'lg:space-x-1': !config.action_bar.vertical,
                'rounded-full':
                  (config.action_bar.icon && !config.action_bar.label) ||
                  !config.action_bar.vertical,
                'rounded-3xl space-y-0.5 ':
                  config.action_bar.label && config.action_bar.vertical,
                'z-auto': isEditOpen,
                'dragging group': isDragging,
                'hover:cursor-move bg-black/40 dark:bg-white/40 backdrop-blur-xl border border-black/10 dark:border-white/10':
                  !config.action_bar.is_fixed,
                'p-1': !config.action_bar.is_fixed,
                'shadow-xl': !config.action_bar.is_fixed,
                'self-end justify-end':
                  config.action_bar.is_fixed &&
                  ['top', 'bottom'].includes(config.action_bar.position),
              }
            )}
          >
            {width > 768 &&
              alerts.filter((item) => !item.isDismissed).length >= 1 && (
                <Tooltip
                  title="Account Warning"
                  side={configToTooltipLabelPosition(
                    config.action_bar.position
                  )}
                >
                  <SIconButton
                    icon={AlertsToIcon}
                    onClick={async () => {
                      setAlertsIsOpen(true)
                    }}
                    size="lg"
                    isActive={alertsIsOpen}
                    className={cn(
                      '!border-0 size-12  !rounded-xl',
                      AlertsToClassName,
                      {
                        'md:mb-1': config.action_bar.vertical,
                      }
                    )}
                  />
                </Tooltip>
              )}

            <NavItems key="navitems" />

            {prompt ? (
              <ActionBarItem
                icon={ArrowDownOnSquareIcon}
                name="Install"
                onClick={promptToInstall}
              />
            ) : null}

            {width > 768 && (
              <div
                className={cn({
                  '-order-1 w-full': config.action_bar.is_fixed,
                  'py-1':
                    config.action_bar.vertical && config.action_bar.is_fixed,
                })}
              >
                <ProfileNav key="profileitems" />
              </div>
            )}
          </motion.div>
        </motion.div>
      </motion.div>
    </>
  )
}
